const imageVersion = '1.0.7';
const cdnUrl = process.env.NEXT_PUBLIC_CDN_URL;

const cdnLoader = (src) => {
    const path = typeof src === 'object' ? src.src : src;
    if (/^https?:\/\//.test(path) || !src) {
        return path;
    }
    return `${cdnUrl}${path}?v=${imageVersion}`;
};

module.exports = { imageVersion, cdnUrl, cdnLoader };